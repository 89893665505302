import React, { useState, useEffect } from 'react';
import DeleteProduct from './DeleteProduct';
import './DeleteProduct.css';
import './ProductsList.css';
import Tooltip from './icons/Tooltip';
import PriceHistoryGraph from './PriceHistoryGraph';

const ProductsList = ({ userID, productData, refreshProducts, setShowPlans, trackers, setactivateProduct, setActiveProductID, refreshUsers, language }) => {
    const [products, setProducts] = useState([]);
    const [priceHistoryState, setPriceHistoryState] = useState({});
    const [popupProductID, setPopupProductID] = useState(null);

    useEffect(() => {
        fetch(`/getProducts?id=${userID}`)
            .then(response => response.json())
            .then(data => {
                if (data.productsList.success) {
                    setProducts(data.productsList.results);
                }
            })
            .catch(error => console.error('Error fetching products:', error));
    }, [userID, productData]);

    const calculateTimeRemaining = (endTime) => {
        const now = new Date();
        const end = new Date(endTime);
        const timeDiff = end - now;

        if (timeDiff <= 0) return 'Expired';

        const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

        return `${days}`;
    };

    const fetchPriceHistory = (productID) => {
        fetch(`/getPriceHistory?id=${productID}`)
            .then(response => response.json())
            .then(data => {
                if (data.priceHistory && data.priceHistory.success) {
                    setPriceHistoryState(prevState => ({
                        ...prevState,
                        [productID]: {
                            show: true,
                            history: data.priceHistory.results
                        }
                    }));
                }
            })
            .catch(error => console.error('Error fetching price history:', error));
    };

    const togglePriceHistory = (productID) => {
        setPriceHistoryState(prevState => {
            const isShowing = prevState[productID]?.show;
            if (isShowing) {
                return {
                    ...prevState,
                    [productID]: { show: false, history: [] }
                };
            } else {
                fetchPriceHistory(productID);
                return {
                    ...prevState,
                    [productID]: { ...prevState[productID], show: true }
                };
            }
        });
    };

    const handleActivateProduct = async (productID) => {
        if (trackers === 0) {
            setShowPlans(true);
            setPopupProductID(null);
            setactivateProduct(true);
            setActiveProductID(productID);
        } else {
            try {
                const payload = { productID, userID };
                await fetch('/activateProduct', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(payload)
                });
                setactivateProduct(null);
                setActiveProductID(null);
                refreshProducts();
                setPopupProductID(null);
                refreshUsers();
            } catch (error) {
                console.error('Error confirming product:', error);
            }
        }
    };

    const handleCancelActivateProduct = async () => {
        setactivateProduct(null);
        setActiveProductID(null);
        setPopupProductID(null);
    };

/*     const calculateTimeAgo = (timestamp) => {
        const currentTime = new Date();
        const historyTime = new Date(timestamp);
        const timeDiff = currentTime - historyTime;
        const hoursDiff = Math.floor(timeDiff / (1000 * 60 * 60));
        const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

        if (hoursDiff <= 1) {
            return t.oneHour;
        } else if (hoursDiff < 24) {
            return `${hoursDiff} ${t.hoursAgo}`;
        } else {
            return `${daysDiff} ${t.daysAgo}`;
        }
    };
 */
    const texts = {
        en: {
            heading: 'Your Thryllos',
            noHeading: 'You have no trackers set up yet...',
            noSub: 'Start tracking items by adding a link, and we\'ll notify you when the price drops.',
            expired: 'This item has expired',
            reactivate: 'Reactivate this thryllo',
            store: 'Store:',
            history: 'Price History:',
            use: 'Use a tracker to monitor the price and receive alerts again?',
            activate: 'Yes, let\'s activate it',
            non: 'No',
            oneHour: '1 hour ago',
            hoursAgo: 'hours ago',
            daysAgo: 'days ago'
        },
        fr: {
            heading: 'Vos Thryllos',
            noHeading: 'Vous n\'avez pas encore configuré de trackers...',
            noSub: 'Commencez à suivre des articles en ajoutant un lien, et nous vous informerons lorsque le prix baissera.',
            expired: 'Cet article a expiré',
            reactivate: 'Réactiver ce thryllo',
            store: 'Magasin :',
            history: 'Historique des prix :',
            use: 'Utilisez un tracker pour surveiller le prix et recevoir à nouveau des alertes ?',
            activate: 'Oui, activons-le',
            non: 'Non',
            oneHour: 'il y a 1 heure',
            hoursAgo: 'heures',
            daysAgo: 'jours restant'
        },
        es: {
            heading: 'Tus Thryllos',
            noHeading: 'Aún no has configurado ningún rastreador...',
            noSub: 'Comienza a rastrear artículos añadiendo un enlace, y te notificaremos cuando el precio baje.',
            expired: 'Este artículo ha expirado',
            reactivate: 'Reactivar este thryllo',
            store: 'Tienda:',
            history: 'Historial de precios:',
            use: '¿Usar un rastreador para monitorear el precio y recibir alertas nuevamente?',
            activate: 'Sí, activémoslo',
            non: 'No',
            oneHour: 'hace 1 hora',
            hoursAgo: 'hace horas',
            daysAgo: 'hace días'
        },
        de: {
            heading: 'Ihre Thryllos',
            noHeading: 'Sie haben noch keine Tracker eingerichtet...',
            noSub: 'Beginnen Sie mit dem Verfolgen von Artikeln, indem Sie einen Link hinzufügen, und wir benachrichtigen Sie, wenn der Preis fällt.',
            expired: 'Dieser Artikel ist abgelaufen',
            reactivate: 'Reaktivieren Sie diesen thryllo',
            store: 'Geschäft:',
            history: 'Preisentwicklung:',
            use: 'Verwenden Sie einen Tracker, um den Preis zu überwachen und erneut Benachrichtigungen zu erhalten?',
            activate: 'Ja, lass es uns aktivieren',
            non: 'Nein',
            oneHour: 'vor 1 Stunde',
            hoursAgo: 'vor Stunden',
            daysAgo: 'vor Tagen'
        },
        lt: {
            heading: 'Jūsų Thryllo stebėjimai',
            noHeading: 'Dar neturite nustatytų stebėjimų...',
            noSub: 'Pradėkite stebėti prekes pridėdami nuorodą, ir mes pranešime jums, kai kaina sumažės.',
            expired: 'Šis prekės stebėjimas pasibaigė',
            reactivate: 'Atnaujinti šį thryllo',
            store: 'Parduotuvė:',
            history: 'Kainų istorija:',
            use: 'Ar norėtumėte vėl sekti kainą ir gauti pranešimus?',
            activate: 'Taip, aktyvuokime tai',
            non: 'Ne',
            oneHour: 'prieš 1 valandą',
            hoursAgo: 'prieš valandas',
            daysAgo: 'prieš dienas'
        }
    };

    const t = texts[language]; // This assigns the correct language's texts based on the selected language

    return (
        <div className='productsList'>
            {
                products.length > 0 ? (
                    <h3 className='capText'>{t.heading}</h3>
                ) : (
                    <div className='loadingScreen'>
                        <h2 className='noMarginP'>🫤</h2>
                        <h3 className='centeredText noMarginP'>{t.noHeading}</h3>
                        <p className='centeredText noMarginP'>{t.noSub}</p>
                    </div>
                )
            }
            <ul>
                {products.map(product => (
                    <li key={product.productID}>
                        {calculateTimeRemaining(product.TimeEnd) === 'Expired' ? (
                            <div className='listElement marginB'>
                                <div className='listFlex'>
                                    <img className='productImg' src={product.productImg} alt={product.productTitle} />
                                    <div className='listFlex fillContainer nonRev'>
                                        <div className='fillContainer'>
                                            <p className='noMarginP'>{t.expired}</p>
                                            <button className='btnBasic margT' onClick={() => setPopupProductID(product.productID)}>{t.reactivate}</button>
                                        </div>
                                        <div className='baseHFlex'>
                                            <div className='pntr deleteElement' onClick={() => window.open(product.productLink, "_blank", "noopener noreferrer")}>
                                                <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12.2226 26.8984C11.8321 27.2889 11.8321 27.9221 12.2226 28.3126C12.6131 28.7031 13.2463 28.7031 13.6368 28.3126L12.2226 26.8984ZM28.0718 13.4633C28.0718 12.911 27.6241 12.4633 27.0718 12.4633L18.0718 12.4633C17.5195 12.4633 17.0718 12.911 17.0718 13.4633C17.0718 14.0156 17.5195 14.4633 18.0718 14.4633H26.0718V22.4633C26.0718 23.0156 26.5195 23.4633 27.0718 23.4633C27.6241 23.4633 28.0718 23.0156 28.0718 22.4633V13.4633ZM13.6368 28.3126L27.7789 14.1704L26.3647 12.7562L12.2226 26.8984L13.6368 28.3126Z" fill="#D6D6D6" />
                                                </svg>
                                            </div>
                                            <DeleteProduct productID={product.productID} userID={userID} refreshProducts={refreshProducts} language={language} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className='listElement marginB'>
                                <div className='listFlex'>
                                    <img className='productImg' src={product.productImg} alt={product.productTitle} />
                                    <div className='listFlex fillContainer nonRev'>
                                        <div className='fillContainer'>
                                            <p className='noMarginP'>{product.productTitle}</p>
                                            <p className='smallP capText lightText'><strong>{t.store} {product.productCategory}</strong></p>
                                            <h3>{product.productPrice}</h3>
                                            <div className="price-tracker">
                                                <p className="smallP noMarginP">{calculateTimeRemaining(product.TimeEnd)} Days left</p>
                                                <span className="tooltip">
                                                    <Tooltip />
                                                    <span className="tooltiptext">
                                                        This price will be tracked for {calculateTimeRemaining(product.TimeEnd)} more days.
                                                    </span>
                                                </span>
                                            </div>
                                            <button className='btnBasic' onClick={() => togglePriceHistory(product.productID)}>
                                                {priceHistoryState[product.productID]?.show ? 'Hide price history' : 'Show price history'}
                                            </button>
                                            {priceHistoryState[product.productID]?.show && priceHistoryState[product.productID]?.history && (
                                                <div>
                                                    <h4>{t.history}</h4>
                                                    {/* <ul>
                                                        {priceHistoryState[product.productID].history.map(history => (
                                                            <li key={history.priceID} className='marginS'>
                                                                {history.price} - {calculateTimeAgo(history.timestamp)}
                                                            </li>
                                                        ))}
                                                    </ul> */}
                                                    <PriceHistoryGraph history={priceHistoryState[product.productID].history} />
                                                </div>
                                            )}
                                        </div>
                                        <div className='baseHFlex'>
                                            <div className='pntr deleteElement' onClick={() => window.open(product.productLink, "_blank", "noopener noreferrer")}>
                                                <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12.2226 26.8984C11.8321 27.2889 11.8321 27.9221 12.2226 28.3126C12.6131 28.7031 13.2463 28.7031 13.6368 28.3126L12.2226 26.8984ZM28.0718 13.4633C28.0718 12.911 27.6241 12.4633 27.0718 12.4633L18.0718 12.4633C17.5195 12.4633 17.0718 12.911 17.0718 13.4633C17.0718 14.0156 17.5195 14.4633 18.0718 14.4633H26.0718V22.4633C26.0718 23.0156 26.5195 23.4633 27.0718 23.4633C27.6241 23.4633 28.0718 23.0156 28.0718 22.4633V13.4633ZM13.6368 28.3126L27.7789 14.1704L26.3647 12.7562L12.2226 26.8984L13.6368 28.3126Z" fill="#D6D6D6" />
                                                </svg>
                                            </div>
                                            <DeleteProduct productID={product.productID} productTitle={product.productTitle} userID={userID} refreshProducts={refreshProducts} language={language} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {popupProductID === product.productID && (
                            <div className="popup-overlay">
                                <div className="popup">
                                    <p className='noMarginP'>{t.use}</p>
                                    <div className="popup-buttons">
                                        <button className='btnBasic' onClick={() => handleActivateProduct(product.productID)}>{t.activate}</button>
                                        <button className='btnBasic greyBtn' onClick={() => handleCancelActivateProduct()}>{t.non}</button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ProductsList;
