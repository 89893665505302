import React, { useState, useEffect } from 'react';
import './ProfileTrigger.css';

const ProfileTrigger = ({ email, handleLogout, language }) => {
    const [showDropdown, setShowDropdown] = useState(false);

    const handleClickOutside = (event) => {
        if (!event.target.closest('.profileTrigger')) {
            setShowDropdown(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const initial = email.charAt(0).toUpperCase();

    const texts = {
        en: {
            contactSupport: 'Contact Support',
            logOut: 'Log out',
        },
        fr: {
            contactSupport: 'Contacter le support',
            logOut: 'Se déconnecter',
        },
        es: {
            contactSupport: 'Contactar soporte',
            logOut: 'Cerrar sesión',
        },
        de: {
            contactSupport: 'Kontakt Support',
            logOut: 'Abmelden',
        },
        lt: {
            contactSupport: 'Susisiekite su palaikymo komanda',
            logOut: 'Atsijungti',
        }
    };

    const t = texts[language]; // This assigns the correct language's texts based on the selected language


    return (
        <div className="profileTrigger">
            <div
                className="profileCircle noMarginP"
                onClick={(e) => {
                    e.stopPropagation();
                    setShowDropdown(!showDropdown);
                }}
            >
                {initial}
            </div>
            {showDropdown && (
                <div className="dropdown">
                    <p className='fullEmail'>{email}</p>
                    <p><a className='baseLink' href="/support" target="_blank">{t.contactSupport}</a></p>
                    <button className='btnBasic fullWidth' onClick={handleLogout}>{t.logOut}</button>
                </div>
            )}
        </div>
    );
};

export default ProfileTrigger;
