import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import supabase from './SupabaseClient';
import LogoBig from './icons/LogoBig';
import PWHide from './icons/PwHide';
import PwShow from './icons/PwShow';

function SignUp({ language }) {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  /* const [fullname, setFullname] = useState(''); */
  const [message, setMessage] = useState('');
  const { productId } = useParams();
  const [signedUp, setSignedUp] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);

  const handleSignUp = async (e) => {
    e.preventDefault();
    if (password !== passwordConfirmation) {
      setMessage('Passwords do not match');
      return;
    }

    // Conditionally set emailRedirectTo based on presence of productId
    const emailRedirectTo = productId
      ? `${window.location.origin}/confirmedemail/${productId}`
      : `${window.location.origin}/confirmedemail`;
    console.log(emailRedirectTo);

    const { error } = await supabase.auth.signUp({
      email,
      password,
      options: {
        emailRedirectTo: emailRedirectTo
      }
    });

    if (error) {
      setMessage(error.message);
    } else {
      setSignedUp(true);
    }
  };

  const texts = {
    en: {
      almost: 'Almost done! Please check your inbox to verify your email address.',
      signUpEmail: 'Your Email',
      signUpPasswordLabel: 'Your Password (minimum 8 characters)',
      confirmPassword: 'Confirm Password',
      signUp: 'Continue',
      alreadyUser: 'Already a Member Yet?',
      signIn: 'Sign In!',
      heading: 'Track Prices, Buy at the Lowest, Save Money!',
      subHeading: 'Get notified when the price changes on your favorite items.',
      bulletOne: '📈 Real-time price tracking',
      bulletTwo: '⏱️ Instant alerts on price change',
      bulletThree: '😏 Track multiple items at once',
    },
    fr: {
      almost: 'Presque terminé ! Veuillez vérifier votre boîte de réception pour confirmer votre adresse e-mail.',
      signUpEmail: 'Votre adresse e-mail',
      signUpPasswordLabel: 'Votre mot de passe (minimum 8 caractères)',
      confirmPassword: 'Confirmer le mot de passe',
      signUp: 'Continuer',
      alreadyUser: 'Déjà membre ?',
      signIn: 'Se connecter !',
      heading: 'Suivez les prix, achetez au plus bas, économisez de l\'argent !',
      subHeading: 'Recevez une notification lorsque le prix baisse sur vos articles préférés.',
      bulletOne: '📈 Suivi des prix en temps réel',
      bulletTwo: '⏱️ Alertes instantanées sur les baisses de prix',
      bulletThree: '😏 Suivi de plusieurs articles à la fois',
    },
    es: {
      almost: '¡Casi terminado! Por favor, verifica tu bandeja de entrada para confirmar tu dirección de correo electrónico.',
      signUpEmail: 'Tu correo electrónico',
      signUpPasswordLabel: 'Tu contraseña (mínimo 8 caracteres)',
      confirmPassword: 'Confirmar contraseña',
      signUp: 'Continuar',
      alreadyUser: '¿Ya eres miembro?',
      signIn: '¡Iniciar sesión!',
      heading: '¡Sigue los precios, compra al más bajo y ahorra dinero!',
      subHeading: 'Recibe notificaciones cuando baje el precio de tus artículos favoritos.',
      bulletOne: '📈 Seguimiento de precios en tiempo real',
      bulletTwo: '⏱️ Alertas instantáneas de bajada de precios',
      bulletThree: '😏 Sigue varios artículos a la vez',
    },
    de: {
      almost: 'Fast fertig! Bitte überprüfen Sie Ihren Posteingang, um Ihre E-Mail-Adresse zu bestätigen.',
      signUpEmail: 'Ihre E-Mail-Adresse',
      signUpPasswordLabel: 'Ihr Passwort (mindestens 8 Zeichen)',
      confirmPassword: 'Passwort bestätigen',
      signUp: 'Weiter',
      alreadyUser: 'Bereits Mitglied?',
      signIn: 'Anmelden!',
      heading: 'Preise verfolgen, zum niedrigsten Preis kaufen, Geld sparen!',
      subHeading: 'Erhalten Sie Benachrichtigungen, wenn der Preis für Ihre Lieblingsartikel sinkt.',
      bulletOne: '📈 Echtzeit-Preisverfolgung',
      bulletTwo: '⏱️ Sofortige Preisbenachrichtigungen',
      bulletThree: '😏 Mehrere Artikel gleichzeitig verfolgen',
    },
    lt: {
      almost: 'Beveik baigta! Prašome patikrinti savo el. pašto dėžutę, kad patvirtintumėte savo el. pašto adresą.',
      signUpEmail: 'Jūsų el. paštas',
      signUpPasswordLabel: 'Jūsų slaptažodis (mažiausiai 8 simboliai)',
      confirmPassword: 'Patvirtinkite slaptažodį',
      signUp: 'Tęsti',
      alreadyUser: 'Jau esate narys?',
      signIn: 'Prisijungti!',
      heading: 'Sekite kainas, pirkite pigiausiai, taupykite pinigus!',
      subHeading: 'Gaukite pranešimus, kai sumažės jūsų mėgstamų prekių kainos.',
      bulletOne: '📈 Kainų stebėjimas realiu laiku',
      bulletTwo: '⏱️ Momentiniai pranešimai apie kainų sumažėjimą',
      bulletThree: '😏 Vienu metu sekite kelis daiktus',
    }
  };

  const t = texts[language];

  return (
    <div className='onbrdFlex'>
      <div className={signedUp ? 'formLeft fullWidth' : 'formLeft'}>
        <div className='marginB'>
          <LogoBig />
        </div>
        {signedUp && <p className='noMarginP'>{t.almost}</p>}
        {!signedUp && <div className='formElements'>
          <form className='formElements' onSubmit={handleSignUp}>
            {message && <p className='noMarginP redText centeredText'>{message}</p>}
            <input className='mainInput inputSmall' type="email" id="emailInput" value={email} onChange={(e) => setEmail(e.target.value)} placeholder={t.signUpEmail} />
            <div className='passwordField'>
              <input className='mainInput inputSmall PwInput' type={showPassword ? "text" : "password"} id="passwordInput" value={password} onChange={(e) => setPassword(e.target.value)} placeholder={t.signUpPasswordLabel} />
              <button type="button" onClick={() => setShowPassword(!showPassword)} className="togglePasswordVisibility">
                {showPassword ? <PWHide /> : <PwShow />}
              </button>
            </div>
            {password && <div className='formElements' id='confirmPWinput'>
              <div className='passwordField'>
                <input
                  className='mainInput inputSmall PwInput'
                  type={showPasswordConfirmation ? "text" : "password"}
                  id="confirmPassword"
                  value={passwordConfirmation}
                  onChange={(e) => setPasswordConfirmation(e.target.value)}
                  placeholder={t.confirmPassword}
                />
                <button
                  type="button"
                  onClick={() => setShowPasswordConfirmation(!showPasswordConfirmation)}
                  className="togglePasswordVisibility"
                >
                  {showPasswordConfirmation ? <PWHide /> : <PwShow />}
                </button>
              </div>
            </div>}
            {/* {(email && password && passwordConfirmation) && (
              <div className='formElements'>
                <input 
                  className='mainInput inputSmall' 
                  type="text" 
                  id="yourName" 
                  value={fullname} 
                  onChange={(e) => setFullname(e.target.value)} 
                  placeholder="Your fullname"
                />
              </div>
            )} */}
            <button className='btnBasic' type="submit">{t.signUp}</button>
          </form>
          <p className='centeredText noMarginP'>{t.alreadyUser} <a className='redLink' href="/signin"><strong>{t.signIn}</strong></a></p>
        </div>}
      </div>
      {!signedUp && <div className='formRight'>
        <h1 className='capText'>{t.heading}</h1>
        <p className='noMarginP whiteText'>{t.subHeading}</p>
        <ul>
          <li className='marginS'>{t.bulletOne}</li>
          <li className='marginS'>{t.bulletTwo}</li>
          <li className='marginS'>{t.bulletThree}</li>
        </ul>
      </div>}
    </div>
  );
}

export default SignUp;