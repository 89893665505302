import React, { useState, useEffect } from "react";
import MainLogo from './icons/MainLogo';
import UserData from './UserData';
import ProfileTrigger from './ProfileTrigger';
import supabase from './SupabaseClient';

const NavBar = ({ language }) => {
  const [session, setSession] = useState(null);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const { data: { subscription } } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        setSession(session);
      }
    );

    return () => {
      subscription?.unsubscribe();
    };
  }, []);

  const handleLogout = async () => {
    await supabase.auth.signOut();
  };

  const texts = {
    en: {
      join: 'Start for free',
    },
    fr: {
      join: 'Commencez gratuitement',
    },
    es: {
      join: 'Únete gratis',
    },
    de: {
      join: 'Kostenlos beitreten',
    },
    lt: {
      join: 'Prisijunkite nemokamai',
    }
  };

  const t = texts[language];

  return (
    <nav>
      <MainLogo session={session} />
      {session ? (
        <>
          <div className='centeredFlex'>
            <UserData userID={session.user.id} setUserData={setUserData} />
            <p className='noMarginP'>Trackers: {userData ? userData.trackers : 0}</p>
            <ProfileTrigger email={session.user.email} handleLogout={handleLogout} language={language} />
          </div>
        </>
      ) : (
        <>
          <button className='btnBasic btnBig' onClick={() => window.location.href = "/signup"}>{t.join}</button>
        </>
      )}
    </nav>
  );
};

export default NavBar;